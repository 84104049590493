import { render, staticRenderFns } from "./Badges.vue?vue&type=template&id=37be7c04&scoped=true&"
import script from "./Badges.vue?vue&type=script&lang=js&"
export * from "./Badges.vue?vue&type=script&lang=js&"
import style0 from "./Badges.vue?vue&type=style&index=0&id=37be7c04&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37be7c04",
  null
  
)

export default component.exports